.nav-link {
	color: #000;
}

.nav-link:hover {
	color: #6e08f4;
}

.color-purple-link {
	color: #6e08f4;
}

.nav-link.active {
	color: #6e08f4 !important;
}

.navbar-button-spacer {
	margin-top: 20px;
	margin-left: 35px;
	margin-bottom: 20px;
}
.navbar-nav .nav-link {
	margin-top: 20px;
	margin-left: 35px;
	margin-bottom: 20px;
}
