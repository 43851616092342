.outline-purple {
	--bs-btn-color: #6e08f4 !important;
	--bs-btn-border-color: #6e08f4 !important;
	--bs-btn-border-width: 2px !important;
	--bs-btn-hover-color: #fff !important;
	--bs-btn-hover-bg: #6e08f4 !important;
	--bs-btn-hover-border-color: #6e08f4 !important;
	--bs-btn-hover-border-width: 2px !important;
	--bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
	--bs-btn-active-color: #fff !important;
	--bs-btn-active-bg: #6e08f4 !important;
	--bs-btn-active-border-color: #6e08f4 !important;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	--bs-btn-disabled-color: #6e08f4 !important;
	--bs-btn-disabled-bg: transparent !important;
	--bs-btn-disabled-border-color: #6e08f4 !important;
	--bs-gradient: none;
	--bs-btn-border-radius: 400px !important;
}

.outline-orange {
	--bs-btn-color: #fd841f !important;
	--bs-btn-border-color: #fd841f !important;
	--bs-btn-border-width: 2px !important;
	--bs-btn-hover-color: #fff !important;
	--bs-btn-hover-bg: #fd841f !important;
	--bs-btn-hover-border-color: #fd841f !important;
	--bs-btn-hover-border-width: 2px !important;
	--bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
	--bs-btn-active-color: #fff !important;
	--bs-btn-active-bg: #fd841f !important;
	--bs-btn-active-border-color: #fd841f !important;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	--bs-btn-disabled-color: #fd841f !important;
	--bs-btn-disabled-bg: transparent !important;
	--bs-btn-disabled-border-color: #fd841f !important;
	--bs-gradient: none;
}

.avatar {
	width: 210px;
	margin: 3rem 0;
	height: auto;
	max-width: 100%;
}

.desk {
	width: 810px;
	height: auto;
	max-width: 100%;
}

.software {
	width: 190px;
	height: auto;
	max-width: 100%;
	opacity: 0.5;
}

.bg-purple {
	background-color: #6e08f4;
	color: #fff;
}

.color-purple {
	color: #6e08f4 !important;
}

.color-orange {
	color: #fd841f !important;
}

.color-transparent {
	opacity: 85%;
}

.card-hover {
	margin-top: -300px;
}

.card-image {
	width: 75px !important;
	height: auto;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}

.container-spacer {
	padding-bottom: 300px;
}

.card-shadow {
	box-shadow: 0 5px 5px 0 rgba(233, 240, 243, 0.5), 0 0 0 1px #e6ecf8;
	border-color: #fefefe !important;
}

.width-three-fifths {
	width: 75%;
	height: auto;
	max-width: 100%;
}

.btn-font-size-normal {
	--bs-btn-font-size: 1rem !important;
}
