.hover-container {
	position: relative;
}

.img-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: 0.5s ease;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: 0.375rem;
}

.hover-container:hover .img-overlay {
	opacity: 1;
}

.hover-container:hover .buttons-overlay {
	opacity: 1;
}

.buttons-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	opacity: 0;
}

.projects-card-outline-purple {
	--bs-btn-color: #fff !important;
	--bs-btn-bg: #6e08f4 !important;
	--bs-btn-border-color: #6e08f4 !important;
	--bs-btn-border-width: 2px !important;
	--bs-btn-hover-color: #fff !important;
	--bs-btn-hover-border-color: #6e08f4 !important;
	--bs-btn-hover-border-width: 2px !important;
	--bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
	--bs-btn-active-color: #fff !important;
	--bs-btn-active-bg: #6e08f4 !important;
	--bs-btn-active-border-color: #6e08f4 !important;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	--bs-btn-disabled-color: #6e08f4 !important;
	--bs-btn-disabled-bg: transparent !important;
	--bs-btn-disabled-border-color: #6e08f4 !important;
	--bs-gradient: none;
	--bs-btn-border-radius: 400px !important;
}

.projects-card-outline-orange {
	--bs-btn-color: #fff !important;
	--bs-btn-bg: #fd841f !important;
	--bs-btn-border-color: #fd841f !important;
	--bs-btn-border-width: 2px !important;
	--bs-btn-hover-color: #fff !important;
	--bs-btn-hover-border-color: #fd841f !important;
	--bs-btn-hover-border-width: 2px !important;
	--bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
	--bs-btn-active-color: #fff !important;
	--bs-btn-active-bg: #fd841f !important;
	--bs-btn-active-border-color: #fd841f !important;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	--bs-btn-disabled-color: #fd841f !important;
	--bs-btn-disabled-bg: transparent !important;
	--bs-btn-disabled-border-color: #fd841f !important;
	--bs-gradient: none;
	--bs-btn-border-radius: 400px !important;
}

.top-border {
	border-top: 1px solid #e6ecf8;
}

.portfolio-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
